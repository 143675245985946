html,
body {
  height: 100%;
}


.login-wrapper {
  height: 100%;
}


.box {
  position: relative;
  top: 0;
  opacity: 1;
  float: left;
  padding: 60px 50px 40px 50px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 5;
  max-width: 330px;
}


.login-wrapper .login-form {
  min-width: 100%;
  max-width: 300px;
  width: 100%;
}

.login-wrapper .login-full-width,
.login-wrapper .btn-block {
  width: 100%;
}

.login-wrapper mat-card-header {
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 700;
}

.login-wrapper mat-card-header mat-card-title {
  font-size: 30px;
  margin: 0;
}

.login-wrapper .mat-card {
  padding: 40px 70px 50px;
}

.login-wrapper .mat-stroked-button {
  border: 1px solid currentColor;
  line-height: 54px;
  background: #FFF7FA;
}

.login-wrapper .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.8375em 0;
}
